import dayjs from 'dayjs'

export function isClosed() {
  return (
    window.CONFIG.closeTime &&
    dayjs().isAfter(
      `${dayjs().format('YYYY/MM/DD')} ${window.CONFIG.closeTime}`,
      'second'
    )
  )
}

export function isUnStart() {
  return (
    window.CONFIG.startTime &&
    dayjs().isBefore(
      `${dayjs().format('YYYY/MM/DD')} ${window.CONFIG.startTime}`,
      'second'
    )
  )
}
