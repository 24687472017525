<template>
  <div>
    <van-card
      v-for="food in items"
      :key="food.uid"
      :title="food.name"
      :price="food | getPrice"
      :origin-price="food | getOriginPrice"
      :thumb="food.image"
      :desc="food | getKouWei"
    >
      <template #footer>
        <div class="flex items-center justify-end">
          <van-button
            v-if="food.quantity"
            icon="minus"
            type="danger"
            plain
            class="van-add-button van-stepper__minus"
            size="mini"
            round
            @click="() => removeItem(food)"
          />
          <div
            class="van-stepper__input"
            style="height:initial;"
            v-if="food.quantity"
          >
            {{ food.quantity }}
          </div>
          <van-button
            icon="plus"
            type="danger"
            class="van-add-button van-stepper__plus"
            size="mini"
            round
            @click="() => addItem(food)"
          />
        </div>
      </template>
    </van-card>
  </div>
</template>
<script>
import { Toast } from 'vant'
export default {
  methods: {
    addItem(item, quantity = 1) {
      if (item.maxqty && this.quantity + quantity > item.maxqty) {
        Toast('已达最大限购数量')
        return
      }
      if (item.minqty && !this.quantity) {
        quantity = this.food.minqty
        Toast(`${item.minqty}份起售`)
      }
      this.$store.dispatch('addItem', {
        item,
        quantity
      })
    },

    removeItem(item, quantity = 1) {
      if (item.minqty && this.quantity === item.minqty) {
        quantity = this.food.minqty
        Toast(`${item.minqty}份起售`)
      }
      this.$store.dispatch('removeItem', {
        item,
        quantity
      })
    }
  },

  computed: {
    items() {
      return this.$store.getters.cartItems
    }
  }
}
</script>
<style>
</style>