<template>
  <div>
    <van-card
      :title="food.name"
      :price="food | getPrice"
      :desc="food.memo"
      :thumb="food | getImageUrl"
      :origin-price="food | getOriginPrice"
      @click="goDetail"
      class="bg-white"
      lazy-load
    >
      <template #footer>
        <div
          class="flex items-center justify-end"
          v-if="!food.codestate"
          @click.stop
        >
          <van-button
            v-if="quantity"
            icon="minus"
            type="danger"
            plain
            class="van-add-button van-stepper__minus"
            size="mini"
            round
            @click="() => removeItem(food)"
          />
          <div
            class="van-stepper__input"
            style="height: initial"
            v-if="quantity"
          >
            {{ quantity }}
          </div>
          <van-button
            icon="plus"
            type="danger"
            class="van-add-button van-stepper__plus"
            size="mini"
            round
            @click="select"
          />
        </div>
        <div v-else class="flex items-center justify-end">
          <div class="text-gray-400">已售罄</div>
        </div>
      </template>
    </van-card>
    <van-popup
      class="p-4 flex flex-col"
      style="border-radius: 10px 10px 0 0; min-height: 400px"
      v-model="showSpecSelector"
      position="bottom"
      closeable
    >
      <div class="flex">
        <van-image
          class="rounded-lg overflow-hidden"
          :src="imageUrl"
          width="80px"
          height="80px"
        />
        <div class="flex flex-col justify-between ml-2">
          <div class="font-semibold text-sm">{{ food.name }}</div>
          <div class="font-bold">￥{{ food | getPrice }}</div>
        </div>
      </div>
      <div>
        <div class="my-2 text-sm text-gray-500">口味：</div>
        <div class="flex flex-wrap">
          <van-button
            type="danger"
            class="mr-4 mb-4"
            size="small"
            :plain="spec | isUnSelected(selectedSpecs)"
            v-for="spec in specs"
            :key="spec.id"
            @click="() => selectSpec(spec)"
          >
            {{ spec.kwname }}
          </van-button>
        </div>
        <div class="my-2 flex items-center justify-between">
          <div class="text-sm text-gray-500">数量：</div>
          <van-stepper v-model="specQuantity" min="1" />
        </div>
      </div>
      <div class="flex-grow" />
      <van-button
        type="danger"
        class="rounded-full"
        block
        @click="onSpecSubmit"
      >
        加入购物车
      </van-button>
    </van-popup>
  </div>
</template>

<script>
import { foodMixin } from '@/mixin'

export default {
  mixins: [foodMixin],
  methods: {
    goDetail() {
      this.$router.push({
        name: 'food',
        params: {
          code: this.food.code
        }
      })
    }
  }
}
</script>

<style>
.van-stepper__input {
  background-color: transparent;
}
.van-add-button {
  width: 22px;
  height: 22px;
  margin-left: 0 !important;
}
</style>