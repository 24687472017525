import { Toast } from 'vant'
import { getKouWei } from '@/api'

export const foodMixin = {
  props: ['food'],

  data() {
    return {
      showSpecSelector: false,
      selectedSpecs: [],
      specQuantity: 1,
      specs: null
    }
  },

  methods: {
    selectSpec(spec) {
      const current = this.selectedSpecs.find(item => item.id === spec.id)
      if (current) {
        this.selectedSpecs = this.selectedSpecs.filter(
          item => item.id !== spec.id
        )
      } else {
        this.selectedSpecs = this.selectedSpecs.concat([spec])
      }
    },
    add() {
      if (this.specs?.length) {
        this.specQuantity = 1
        this.selectedSpecs = []
        this.showSpecSelector = true
      } else {
        this.addItem(this.food)
      }
    },
    select() {
      if (!this.specs) {
        getKouWei({
          code: this.food.code
        }).then(specs => {
          this.specs = specs
          this.add()
        })
      } else {
        this.add()
      }
    },
    onSpecSubmit() {
      this.addItem(
        {
          ...this.food,
          specs: this.selectedSpecs
        },
        this.specQuantity
      )
      this.showSpecSelector = false
    },
    addItem(item, quantity = 1) {
      if (this.food.maxqty && this.quantity + quantity > this.food.maxqty) {
        Toast('已达最大限购数量')
        return
      }
      if (this.food.minqty && !this.quantity) {
        quantity = this.food.minqty
        Toast(`${this.food.minqty}份起售`)
      }
      this.$store.dispatch('addItem', {
        item,
        quantity
      })
    },

    removeItem(item, quantity = 1) {
      if (this.food.specs?.length) {
        Toast('多规格商品请在购物车中删除')
        return
      }
      if (this.food.minqty && this.quantity === this.food.minqty) {
        quantity = this.food.minqty
        Toast(`${this.food.minqty}份起售`)
      }
      this.$store.dispatch('removeItem', {
        item,
        quantity
      })
    }
  },

  filters: {
    isUnSelected: (spec, specs) => {
      return !specs.some(item => item.id === spec.id)
    }
  },

  computed: {
    imageUrl() {
      return `${location.origin}/dishimages/${this.food.code}.png`
    },

    quantity() {
      return this.$store.getters.cartFoodTotalQuantity(this.food.code)
    }
  }
}
