<template>
  <div class="flex flex-col h-screen">
    <div v-if="bannerUrl" class="banner">
      <van-image class="w-full" :src="bannerUrl" />
    </div>
    <div class="text-base p-4 bg-white font-semibold" v-if="date && orderType">
      <span>{{ date }}</span
      ><span class="ml-2">{{ orderType }}</span>
    </div>
    <div class="flex-grow flex items-stretch overflow-hidden relative">
      <van-sidebar
        v-model="activeCategoryId"
        class="flex-shrink-0 overflow-scroll"
        @change="handleCategoryChange"
      >
        <van-sidebar-item
          v-for="category in categories"
          :title="category.name"
          :key="category.id"
        />
        <div style="height:50px" />
      </van-sidebar>
      <div class="flex-grow overflow-scroll bg-white" id="list">
        <van-index-bar ref="list" :index-list="ids" @change="onIndexChange">
          <div v-for="category in categories" :key="category.id">
            <van-index-anchor :index="category.id">
              <!-- {{ category.name }} -->{{ ' ' }}
            </van-index-anchor>
            <div class="text-red-500 pl-4 font-semibold py-1">
              {{ category.name }}
            </div>
            <van-divider class="my-0" />
            <menu-item
              v-for="food in category.foods"
              :key="food.id"
              :food="food"
            />
          </div>
        </van-index-bar>
        <div style="height:50px" />
      </div>
    </div>
    <van-submit-bar button-text="结算" :disabled="!count" @submit="submit">
      <div class="flex-grow">
        <van-icon
          name="cart"
          size="20"
          :badge="count || ''"
          @click="showCart = true"
        />
      </div>
    </van-submit-bar>
    <div
      class="bg-red-500 absolute right-2 bottom-20 rounded-full flex flex-col items-center justify-center text-white"
      style="width: 50px; height: 50px;"
      @click="goOrders"
    >
      <van-icon name="orders-o" class="text-white" size="20" />
      <div class="text-xs font-semibold">订单</div>
    </div>
    <van-popup v-model="showCart" position="bottom" closeable>
      <div class="pt-12 pb-8 px-4">
        <Cart />
      </div>
    </van-popup>
  </div>
</template>

<script>
import MenuItem from './menu-item'
import Cart from './cart'
import { getDishesList } from '@/api'
import { Toast } from 'vant'
import { isClosed, isUnStart } from '@/utils'

export default {
  components: {
    MenuItem,
    Cart
  },

  data() {
    return {
      categories: [],
      activeCategoryId: 0,
      showCart: false,
      bannerUrl: window.CONFIG.bannerUrl
    }
  },

  methods: {
    goOrders() {
      this.$router.push({
        name: 'orders'
      })
    },
    addItem(item, quantity = 1) {
      this.$store.dispatch('addItem', {
        item,
        quantity
      })
    },

    submit() {
      if (isClosed() || isUnStart()) {
        Toast({
          message: '门店休息中'
        })
        return
      }
      this.$router.push({
        name: 'checkout'
      })
    },

    onIndexChange(id) {
      const index = this.categories.findIndex(cat => cat.id === id)
      if (this.activeCategoryId !== index) {
        this.changing = true
        this.activeCategoryId = index
        this.$nextTick(() => {
          this.changing = false
        })
      }
    },

    handleCategoryChange(index) {
      if (!this.categories[index] || this.changing) {
        return
      }
      this.$refs.list.scrollTo(this.categories[index].id)
    }
  },

  computed: {
    count() {
      return this.$store.getters.cartCount
    },

    date() {
      return this.$store.state.date
    },

    orderType() {
      return this.$store.state.orderType?.name
    },

    ids() {
      return this.categories.map(item => item.id)
    }
  },

  mounted() {
    getDishesList().then(foods => {
      const categories = []
      foods.sort((a, b) => {
        return a.dishessort - b.dishessort
      })
      foods.forEach(food => {
        if (!Number(food.parentstate)) {
          return
        }
        const currentCategory = categories.find(({ id }) => id === food.parent)
        if (currentCategory) {
          currentCategory.foods.push(food)
        } else {
          categories.push({
            id: food.parent,
            name: food.unionname,
            foods: [food],
            sort: food.parent_sort
          })
        }
      })
      categories.sort((a, b) => {
        return a.sort - b.sort
      })
      const finalCategories = categories.filter(cat => !!cat.foods.length)
      this.categories = finalCategories
    })
  }
}
</script>

<style lang="scss">
#list {
  .van-index-bar__sidebar {
    display: none;
  }
}

.banner {
  position: sticky;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
}
</style>